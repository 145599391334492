import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CompanyLogoImage from '../../../assets/images/company-logo.svg';
import { useGetCurrentStorefrontDetails } from '../../../hooks';

export const CompanyLogo = ({ isNav = false, clickHandler }) => {
  const sellerToken = localStorage.getItem('sellerToken');
  // Retrieve logo URL from session storage
  const [sellerStoreData, setSellerStoreData] = useState([]);
  const storedData = sessionStorage.getItem('storefrontData');
  const parsedData = storedData ? JSON.parse(storedData) : null;
  const storeName = parsedData?.name;
  const mobileLogoUrl =
    sellerStoreData?.logoUrl ||
    parsedData?.logoUrl ||
    sellerStoreData?.longLogoUrl ||
    parsedData?.longLogoUrl;
  const desktopLogoUrl =
    sellerStoreData?.longLogoUrl ||
    parsedData?.longLogoUrl ||
    sellerStoreData?.logoUrl ||
    parsedData?.logoUrl;

  const { data: storefront, refetch } = useGetCurrentStorefrontDetails();

  useEffect(() => {
    if (storefront?.currentStorefront) {
      setSellerStoreData(storefront?.currentStorefront);
      // Convert object to string before storing in session storage
      const serializedData = JSON.stringify(storefront.currentStorefront);
      sessionStorage.setItem('storefrontData', serializedData);
    }
  }, [storefront]);

  useEffect(() => {
    refetch();
  }, [sellerToken, refetch]);

  const renderLogo = () => {
    if (mobileLogoUrl && desktopLogoUrl) {
      return (
        <>
          <img
            src={mobileLogoUrl}
            alt="Salon HQ"
            className="mobile-logo max-h-full"
          />
          <img
            src={desktopLogoUrl}
            alt="Salon HQ"
            className="desktop-logo max-h-full"
          />
        </>
      );
    } else if (mobileLogoUrl || desktopLogoUrl) {
      return <img src={mobileLogoUrl || desktopLogoUrl} alt="Salon HQ" />;
    } else {
      return (
        <h3
          className=" font-bold text-black-secondary text-lg mt-3 truncate w-48"
          onClick={clickHandler}
        >
          {storeName}
        </h3>
      );
    }
  };

  return (
    <div className="contents">
      {isNav ? <NavLink to="/">{renderLogo()}</NavLink> : renderLogo()}
    </div>
  );
};
