import React from 'react';

export const StarDisableIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        opacity="0.5"
        d="M17.9867 7.23091C18.007 7.1474 18.004 7.05993 17.978 6.97804C17.9519 6.89616 17.9039 6.823 17.839 6.76658L17.9867 7.23091ZM17.9867 7.23091C17.9665 7.31442 17.9236 7.39073 17.863 7.45154L17.9867 7.23091ZM12.1298 6.37767L17.4632 7.14211L13.622 10.8232L13.622 10.8232L13.6189 10.8263C13.5044 10.9379 13.4198 11.0768 13.3732 11.2302C13.3279 11.3797 13.32 11.5381 13.3503 11.6915L14.2586 16.9096L9.45873 14.4385C9.32089 14.3646 9.16644 14.3254 9.00902 14.3254C8.85155 14.3254 8.69715 14.3646 8.55935 14.4385L3.7595 16.9096L4.66777 11.6915C4.69815 11.5381 4.69029 11.3796 4.64474 11.2299L4.6447 11.2298C4.59817 11.0769 4.51386 10.9382 4.3995 10.8265L4.39952 10.8265L4.39525 10.8224L0.537099 7.14208L5.87021 6.37767L5.87079 6.37758C6.02572 6.35519 6.17278 6.29489 6.29884 6.20199L6.29886 6.20198C6.42276 6.11067 6.52263 5.99068 6.58991 5.85231L9.0089 1.10326L11.4097 5.85155C11.477 5.99019 11.577 6.11045 11.7011 6.20193L11.7012 6.20203C11.8272 6.29487 11.9742 6.35518 12.1292 6.37758L12.1298 6.37767ZM14.2973 16.935C14.2973 16.935 14.2974 16.935 14.2974 16.935L14.2973 16.935Z"
        fill="white"
        stroke="#585858"
      />
    </svg>
  );
};
