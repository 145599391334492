import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { storageService } from '../services';

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const mainHttpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_API + '/b2b',
});

const shopHttpLink = createHttpLink({
  uri: process.env.REACT_APP_SHOP_API,
});

const httpLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'shop-api',
  // the string "shop-api" can be anything you want,
  // we will use it in a bit
  shopHttpLink, // <= apollo will send to this if clientName is "shop-api"
  mainHttpLink // <= otherwise will send to this
);

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  const needLogout = graphQLErrors?.some(({ message }) =>
    message?.includes('Invalid HQ-Session')
  );

  if (needLogout) {
    storageService.removeAllItems();
    return window.location.reload();
  }
  // eslint-disable-next-line no-unused-expressions
  forward?.(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the sellerToken and marketPlace from localStorage
  const sellerToken = localStorage.getItem('sellerToken');
  const marketPlace = localStorage.getItem('marketPlace');

  // Define an object for custom headers
  const customHeaders = {};

  // Add the HQ-Session header if sellerToken is available
  if (sellerToken) {
    // Scheduler-HQ-Session
    customHeaders['Scheduler-HQ-Session'] = sellerToken;
  }

  // Add the HQ-Marketplace header if marketPlace is available
  if (marketPlace) {
    // Scheduler-HQ-Marketplace;
    customHeaders['Scheduler-HQ-Marketplace'] = marketPlace;
  }

  // Use the setContext method to set the HTTP headers.
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...customHeaders,
    },
  }));

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const clientConfig = new ApolloClient({
  cache: cache,
  // keep this order of links, so errorLink is between auth and http
  link: from([authLink, errorLink, httpLinks]),
  defaultOptions,
});
