import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TotalAppointmentChart = ({ barGraphData }) => {
  const [filter, setFilter] = useState('weekly');

  const data = {
    monthly:
      barGraphData?.monthly?.map((item) => ({
        label: item.month,
        data: item.data,
      })) || [],
    weekly:
      barGraphData?.weekly
        ?.map((item) => {
          const match = String(item.week).match(/\((.*?)\)/);
          const weekDate = match ? new Date(match[1]) : new Date(0); // Use epoch date as fallback
          return {
            label: item.week,
            data: item.data,
            weekDate,
          };
        })
        .sort((a, b) => a.weekDate - b.weekDate)
        .map(({ label, data }) => ({ label, data })) || [],
  };

  const getFilteredData = (filter) => {
    if (!data[filter] || data[filter].length === 0) return null;

    const filteredData = data[filter];
    const statusColors = {
      accepted: '#2c802a',
      completed: '#8f8e8e',
      rescheduled: '#2f80ed',
      canceled: '#d9000d',
    };

    const statusLabels = ['accepted', 'completed', 'rescheduled', 'canceled'];
    const labels = filteredData.map((item) => item.label);

    const datasets = statusLabels.map((status) => {
      return {
        label: status.charAt(0).toUpperCase() + status.slice(1),
        data: filteredData.map((item) => item.data[status] || 0),
        backgroundColor: statusColors[status],
        borderWidth: 1,
      };
    });

    return {
      labels,
      datasets,
    };
  };

  const chartData = getFilteredData(filter);

  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Total Appointment Chart',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        bodyFontSize: 14,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 14,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          fontSize: 14,
        },
      },
      y: {
        stacked: true,
        ticks: {
          fontSize: 14,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const showNoDataMessage = chartData?.datasets?.every((dataset) =>
    dataset.data.every((count) => count === 0)
  );

  return (
    <div className="sm:px-6 px-3 py-5 bg-white-main mt-4 rounded min-h-r11.125">
      <h4 className="lg:text-lg text-sm text-gray-dark font-semibold">
        Total Appointment Chart
      </h4>
      <div className="flex justify-end gap-2 my-4">
        <button
          className={`py-1 px-3 rounded text-sm ${
            filter === 'weekly' ? 'bg-primary text-white-main' : 'bg-gray-light'
          }`}
          onClick={() => setFilter('weekly')}
        >
          Weekly
        </button>
        <button
          className={`py-1 px-3 rounded text-sm ${
            filter === 'monthly'
              ? 'bg-primary text-white-main'
              : 'bg-gray-light'
          }`}
          onClick={() => setFilter('monthly')}
        >
          Monthly
        </button>
      </div>
      <div
        className="text-center grid place-items-center"
        style={{ height: '400px' }}
      >
        {chartData && !showNoDataMessage ? (
          <Bar data={chartData} options={options} />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default TotalAppointmentChart;
