import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetCategories,
  useCreateServices,
  useUpdateServices,
} from '../../hooks';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { serviceFormSchema } from '../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { showError, showSuccess } from '../../utils';
import { Spinner } from '../../components/atoms';
import { MultiImagesForm } from '../../components/templates/multi-images-block/multi-images-form';
import ReactSelect from 'react-select';

const AddService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading } = useGetCategories();
  const handleUpdateServices = useUpdateServices();
  const handleCreateServices = useCreateServices();
  const [imagesData, setImagesData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formParams = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(serviceFormSchema),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = formParams;

  const handleClick = () => navigate(-1);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      name: data?.serviceName,
      categoryUid: data?.category || '',
      description: data?.description || '',
      price: parseFloat(data?.price) || 0,
      durationInMinutes:
        (parseInt(data?.durationHH) * 60 || 0) + parseInt(data?.durationMM),
      status: 'draft',
      images: data?.images,
    };
    const response = await handleCreateServices(payload);
    if (response && response?.data?.createSellerService) {
      showSuccess('Service added successfully');
      navigate('/service');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };
  const onEditService = async (data) => {
    setIsLoading(true);
    const payload = {
      sellerServiceUid: location?.state?.uid,
      input: {
        name: data?.serviceName,
        categoryUid: data?.category || '',
        description: data?.description || '',
        price: parseFloat(data?.price) || 0,
        durationInMinutes:
          (parseInt(data?.durationHH) * 60 || 0) + parseInt(data?.durationMM),
        status: data?.status === '' ? 'deleted' : data?.status,
        images: data?.images,
      },
    };
    const response = await handleUpdateServices(payload);
    if (response && response?.data?.updateSellerService) {
      showSuccess('Service updated successfully');
      navigate('/service');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  useEffect(() => {
    if (data?.categories) {
      setCategories(data?.categories?.nodes);
    }
  }, [data]);

  useEffect(() => {
    if (location?.state) {
      const minutes = location?.state?.durationInMinutes % 60;
      const hours = Math.floor(location?.state?.durationInMinutes / 60);
      setValue('serviceName', location?.state?.name);
      setValue('category', location?.state?.category?.uid);
      setValue('description', location?.state?.description);
      setValue('images', location?.state?.images);
      setValue('durationMM', minutes);
      setValue('star', location?.state?.star);
      if (location?.state?.price !== 0)
        setValue('price', location?.state?.price);
      if (location?.state?.status !== 'deleted') {
        setValue('status', location?.state?.status);
      } else {
        setValue('status', '');
      }
      if (hours !== 0) setValue('durationHH', hours);
    }
  }, [location?.state]);

  const hourOptions = Array.from({ length: 9 }, (_, index) => ({
    value: index,
    label: index.toString(),
  }));

  const minuteOptions = Array.from({ length: 12 }, (_, index) => {
    const value = index * 5;
    return {
      value,
      label: value.toString(),
    };
  });
  const customSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={10}
        style={{ width: '100%', maxWidth: '100%', padding: '2px 5px' }}
      />
    );
  };

  return (
    <>
      {(loading || isLoading) && <Spinner className="loader-cls" />}
      <section className="flex flex-col relative  bg-gray-verylight md:h-screen h-svh md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div>
                <div className="hidden md:block">
                  <span
                    className="text-xs text-gray-middle cursor-pointer"
                    onClick={handleClick}
                  >
                    Services
                  </span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">
                    {location?.state ? 'Edit' : 'Add'} Service
                  </span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                  {location?.state ? 'Edit' : 'Add'} Service
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="relative overflow-y-auto md:pt-5 pt-3 pb-22 flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <FormProvider {...formParams}>
            <form
              className="add-service"
              onSubmit={handleSubmit(
                location?.state ? onEditService : onSubmit
              )}
            >
              <div className="flex md:gap-10 gap-4 md:flex-row flex-col">
                <div className="md:mt-3 relative w-full">
                  <div className="flex justify-center">
                    <MultiImagesForm />
                  </div>
                </div>
              </div>
              <p className="text-r0.8125 text-black-secondary text-left mt-4">
                File Supported: PNG, JPEG, JPG Maximum size: 5MB
              </p>
              <div className="grid-cols-2 gap-x-12 mt-6 lg:grid">
                <div>
                  <div>
                    <label className="text-sm  text-gray-dark">
                      Service Name <span className="text-red-drk">*</span>
                    </label>
                    <Controller
                      name="serviceName"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder="Service Name"
                          className="w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-3"
                        />
                      )}
                    />
                    {errors.serviceName && (
                      <p className="text-red-drk text-r0.6875">
                        {errors.serviceName.message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label className="text-sm  text-gray-dark">
                    Service Category
                  </label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-3"
                      >
                        <option disabled selected>
                          Service Category
                        </option>
                        {categories.map((category) => (
                          <option key={category.uid} value={category.uid}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>

                {location?.state && (
                  <div>
                    <label className="text-sm  text-gray-dark">Status</label>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-3"
                        >
                          <option disabled value={''} selected>
                            Status
                          </option>
                          <option value={'draft'}>Draft</option>
                          <option value={'published'}>Published</option>
                        </select>
                      )}
                    />
                    {errors && errors?.status && (
                      <p className="text-red-drk text-r0.6875 ">
                        {errors?.status?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div>
                <label className="text-sm  text-gray-dark">Description</label>
                <textarea
                  rows="4"
                  placeholder="Description"
                  className="w-full text-xs font-medium rounded border border-gray-light console-input p-2 my-3"
                  {...register('description')}
                />
              </div>
              <h2 className="font-bold my-3 text-xl text-gray-dark text-left">
                Pricing and Duration
              </h2>
              <div className="xs:grid-cols-2 md:gap-x-12 gap-x-2 mt-6 grid">
                <div>
                  <label className="md:text-sm text-sm text-gray-dark">
                    Duration (in hours)
                  </label>
                  <Controller
                    name="durationHH"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        options={hourOptions}
                        classNamePrefix="react-select"
                        placeholder="Duration (hh)"
                        value={hourOptions.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            fontSize: '12px',
                          }),
                        }} // Adjust the font size as needed
                        components={{ Input: customSelectSearchInput }} // Custom input component
                      />
                    )}
                  />
                </div>
                <div className="mt-2 xs:mt-0">
                  <label className="md:text-sm text-sm text-gray-dark">
                    Duration (in minutes){' '}
                    <span className="text-red-drk">*</span>
                  </label>
                  <Controller
                    name="durationMM"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        options={minuteOptions}
                        classNamePrefix="react-select"
                        placeholder="Duration (mm)"
                        value={minuteOptions.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            fontSize: '12px',
                          }),
                        }} // Adjust the font size as needed
                        components={{ Input: customSelectSearchInput }} // Custom input component
                      />
                    )}
                  />
                  {errors && errors?.durationMM && (
                    <p className="text-red-drk text-r0.6875">
                      {errors?.durationMM?.message}
                    </p>
                  )}
                </div>
                <div className="xs:col-span-2 md:col-span-1 my-3">
                  <label className="text-sm  text-gray-dark">Price</label>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="Price"
                        className="w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 "
                        onKeyPress={(event) => {
                          const allowedCharacters = /[0-9.]/; // Regular expression to allow only numeric digits and dot
                          const char = String.fromCharCode(event.which);
                          if (!allowedCharacters.test(char)) {
                            event.preventDefault(); // Prevent entering characters other than numeric digits and dot
                          }
                        }}
                      />
                    )}
                  />
                  {errors.price && (
                    <p className="text-red-drk text-r0.6875">
                      {errors.price.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-3">
                <button
                  type="submit"
                  className="flex-1 md:flex-none h-10 md:w-40 w-full border border-primary bg-primary text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                >
                  Submit
                </button>
              </div>
            </form>
          </FormProvider>
        </section>
      </section>
    </>
  );
};

export default AddService;
