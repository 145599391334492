import * as yup from 'yup';

export const serviceFormSchema = yup.object().shape({
  serviceName: yup
    .string()
    .max(100, 'Please enter maximum 100 letters')
    .required('Please enter service name')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
  durationHH: yup.string(),
  durationMM: yup
    .string()
    .required('Please select duration in min')
    .test(
      'non-zero-minutes',
      'Duration in min cannot be zero if duration in hours is zero or not selected',
      function (value) {
        const { durationHH } = this.parent;
        const durationMM = parseInt(value, 10);
        const durationHHInt = parseInt(durationHH, 10);
        // Both values cannot be zero
        if ((durationHHInt === 0 || isNaN(durationHHInt)) && durationMM === 0) {
          return false;
        }
        return true;
      }
    ),
  price: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? undefined : value;
    })
    .test('is-number', 'Price must be a number', (value) => {
      if (value === undefined) return true;
      return !isNaN(Number(value));
    })
    .test('positive', 'Price must be greater than zero', (value) => {
      if (value === undefined) return true;
      return Number(value) > 0;
    })
    .test(
      'max-value',
      'Price must have up to 5 digits and up to 2 decimal places',
      (value) => {
        if (value === undefined) return true;
        return Number(value) <= 99999.99;
      }
    )
    .when('star', {
      is: true,
      then: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue === '' ? undefined : value;
        })
        .required('Price is required if service is starred')
        .test(
          'not-zero',
          'Price must be greater than zero if service is starred',
          (value) => {
            return Number(value) > 0;
          }
        ),
      otherwise: yup.string().notRequired(),
    }),
  star: yup.boolean(),
});
