import React from 'react';

export const StarColorIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M9.42376 0.815646L11.8581 5.63027C11.89 5.6973 11.938 5.75542 11.9978 5.79947C12.0575 5.8435 12.1273 5.8721 12.2007 5.88273L17.6105 6.65811C17.6951 6.67263 17.7743 6.71016 17.839 6.76658C17.9039 6.823 17.9519 6.89616 17.978 6.97804C18.004 7.05993 18.007 7.1474 17.9867 7.23091C17.9665 7.31442 17.9236 7.39073 17.863 7.45154L13.968 11.1842C13.9137 11.2372 13.8737 11.3029 13.8517 11.3754C13.8297 11.4479 13.8263 11.5248 13.8418 11.599L14.7614 16.8824C14.7767 16.9677 14.7683 17.0557 14.7369 17.1365C14.7053 17.2172 14.6521 17.2877 14.5829 17.34C14.5138 17.3923 14.4316 17.4244 14.3452 17.4327C14.2589 17.4412 14.1719 17.4253 14.0942 17.3873L9.2254 14.8808C9.15906 14.8445 9.08464 14.8254 9.00902 14.8254C8.93339 14.8254 8.85899 14.8445 8.79263 14.8808L3.9239 17.3873C3.84602 17.4253 3.75911 17.4412 3.6728 17.4327C3.58652 17.4244 3.50424 17.3923 3.4351 17.34C3.36597 17.2877 3.3127 17.2172 3.28121 17.1365C3.24971 17.0557 3.24124 16.9677 3.25671 16.8824L4.17636 11.599C4.19186 11.5248 4.18844 11.4479 4.16638 11.3754C4.14431 11.3029 4.10433 11.2372 4.05013 11.1842L0.137119 7.45154C0.0764037 7.39073 0.0335408 7.31442 0.0132545 7.23091C-0.00703187 7.1474 -0.00398447 7.05993 0.0220722 6.97804C0.0481109 6.89616 0.0961849 6.823 0.160993 6.76658C0.225801 6.71016 0.304873 6.67263 0.389571 6.65811L5.79926 5.88273C5.87275 5.8721 5.94248 5.8435 6.00224 5.79947C6.06201 5.75542 6.10998 5.6973 6.14188 5.63027L8.59427 0.815646C8.63409 0.740127 8.69374 0.676923 8.76684 0.632816C8.83993 0.588727 8.92367 0.56543 9.00902 0.56543C9.09438 0.56543 9.17812 0.588727 9.25121 0.632816C9.32429 0.676923 9.38396 0.740127 9.42376 0.815646Z"
        fill="#F5A623"
      />
    </svg>
  );
};
