import * as yup from 'yup';

export const manageSettingsSchema = yup.object().shape({
  days: yup.array().of(
    yup.object().shape({
      enabled: yup.boolean().required(),
      time: yup.array().when('enabled', {
        is: true,
        then: yup.array().of(
          yup.object().shape({
            startTime: yup.string().required('Start time is required'),
            endTime: yup.string().required('End time is required'),
          })
        ),
        otherwise: yup.array().of(
          yup.object().shape({
            startTime: yup.string(),
            endTime: yup.string(),
          })
        ),
      }),
    })
  ),
});
